
import { defineComponent } from 'vue'
import TransactionModel from '@/models/TransactionModel'
import { Payment } from '@/dto'
import moment from 'moment'
import { moneyFormat } from '@/utils/Util'
import { useToast } from 'vue-toastification'

export default defineComponent({
  name: 'Konfirmasi Pembayaran',
  setup() {
    const toast = useToast()
    return {
      toast,
    }
  },
  data() {
    return {
      alert: false,
      code: this.$route.params.code,
      detailTransaction: {} as any,
      steps: [] as any,
      TransactionDate: '',
      selectedPayment: {} as any,
      paymentId: 0,
      uploadDate: '',
      countDown: '',
      showDetailPayment: false,
      showUploadProof: false,
      firstTotal: '' as string,
      endTotal: '' as string,
      formProof: {
        file: null,
        name: '' as string,
        date: '' as string,
        nominal: 0 as number,
      },
      proofPreview: '' as string,
      fileError: false,
      loadingUpload: false,
      isAlreadyUpload: false,
      isExpired: false,
      paymentImages: [
        {
          code: 'ovo',
          images: '/assets/images/payment/logo_ovo.png',
        },
        {
          code: 'dana',
          images: '/assets/images/payment/logo_dana.png',
        },
        {
          code: 'gopay',
          images: '/assets/images/payment/logo_gopay.png',
        },
        {
          code: 'link-aja',
          images: '/assets/images/payment/logo_linkaja.png',
        },
        {
          code: 'shopeepay',
          images: '/assets/images/payment/logo_shopeepay.png',
        },
        {
          code: 'bcatf',
          images: '/assets/images/payment/logo_bca.png',
        },
        {
          code: 'mandiritf',
          images: '/assets/images/payment/logo_mandiri.png',
        },
        {
          code: 'paylater',
          images: '/assets/images/payment/logo_paylater.png',
        },
      ],
    }
  },
  created() {
    this.getDetailTransaction()
  },
  computed: {
    _seconds() {
      return 1000
    },
    _minutes() {
      return 1000 * 60
    },
    _hours() {
      return 60000 * 60
    },
    _days() {
      return 3600000 * 24
    },
    getSubTotal(): number {
      let subTotal = 0
      if (this.detailTransaction?.items) {
        this.detailTransaction.items.forEach((el: any) => {
          subTotal = subTotal + el.price
        })
        return subTotal
      } else {
        return 0
      }
    },
    checkFormProof(): boolean {
      if (
        this.formProof.file &&
        this.formProof.name &&
        this.formProof.date &&
        this.formProof.nominal
      ) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    async getPayment(paymentId: number) {
      const { data } = await TransactionModel.listPayment()
      const find: any = data.find((el: Payment) => el.id == paymentId)
      this.selectedPayment = find
      const payment: any = this.paymentImages.find(
        (el: any) => el.code == this.selectedPayment.code
      )
      this.selectedPayment.images = payment.images
      let steps = this.selectedPayment.instructions
      steps = steps.replace('(tekan lama pada gambar)', '')
      steps = steps.replace('$1', this.selectedPayment.account)
      steps = steps.replace(
        '$2',
        'Rp ' + this.detailTransaction.price_formatted
      )
      steps = steps.replace('$3', this.selectedPayment.receiver)
      this.steps = steps.split(/\\n/)
      this.steps.forEach((el: any, i: number) => {
        this.steps[i] = el.substring(3)
      })
    },
    async getDetailTransaction() {
      const { data, success } = await TransactionModel.listOneTransaction(
        this.code
      )
      if (success) {
        this.detailTransaction = data
        this.endTotal = this.detailTransaction.price_formatted.substr(-3)
        this.firstTotal = this.detailTransaction.price_formatted.substr(
          0,
          this.detailTransaction.price_formatted.length - 3
        )

        this.showCountdown()
        this.TransactionDate = moment(this.detailTransaction.created_at).format(
          'DD MMM YYYY'
        )
        this.uploadDate = moment(this.detailTransaction.expired_at)
          .locale('id')
          .format('DD MMMM YYYY')

        this.getPayment(data.payment_method.id)
      } else {
        this.$router.push('/')
      }
    },

    showCountdown() {
      const timer = setInterval(() => {
        const now = new Date()
        const end = new Date(this.detailTransaction.expired_at)
        const distance = end.getTime() - now.getTime()
        if (distance < 0) {
          this.isExpired = true
          clearInterval(timer)
          this.countDown = 'Melebihi Batas Waktu'
          return
        }
        // const days = Math.floor(distance / this._days)
        const hours = Math.floor(distance / this._hours)
        const minutes = Math.floor((distance % this._hours) / this._minutes)
        const seconds = Math.floor((distance % this._minutes) / this._seconds)
        const displayMinutes = minutes < 10 ? `0` + minutes : minutes
        const displaySeconds = seconds < 10 ? `0` + seconds : seconds
        const displayHours = hours < 10 ? `0` + hours : hours
        // const displayDays = days < 10 ? 0 + days : days
        this.countDown =
          displayHours + ':' + displayMinutes + ':' + displaySeconds
      }, 1000)
    },

    dateFormatted(date: string) {
      return moment(date)
        .locale('id')
        .format('dddd, DD MMMM YYYY HH:mm')
    },

    moneyFormat(price: number) {
      return moneyFormat(price)
    },

    closeDetailPayment() {
      this.showDetailPayment = false
    },

    openUploadProof() {
      this.showUploadProof = true
      if (this.detailTransaction?.proof) {
        this.isAlreadyUpload = true
        this.formProof.name = this.detailTransaction?.sender
        this.formProof.date = moment(this.detailTransaction.send_at).format(
          'DD MMMM YYYY'
        )
        this.formProof.nominal = this.detailTransaction?.nominal_sent
        this.proofPreview = 'https://' + this.detailTransaction.proof
      } else {
        this.formProof.nominal = this.detailTransaction?.price
        this.isAlreadyUpload = false
      }
    },

    closeUploadProof() {
      this.showUploadProof = false
      this.formProof.name = ''
      this.formProof.date = ''
      this.formProof.file = null
      this.proofPreview = ''
      this.fileError = false
    },

    copyRekening() {
      const rekening = this.$refs.rekening as HTMLInputElement
      try {
        rekening.setAttribute('type', 'text')
        rekening.select()
        document.execCommand('copy')
        this.toast.success('Berhasil disalin')
      } catch (err) {
        this.toast.error('Oops, unable to copy')
      }
      rekening.setAttribute('type', 'hidden')
    },

    copyTotal() {
      const total = this.$refs.total as HTMLInputElement
      try {
        total.setAttribute('type', 'text')
        total.select()
        document.execCommand('copy')
        this.toast.success('Berhasil disalin')
      } catch (err) {
        this.toast.error('Oops, unable to copy')
      }
      total.setAttribute('type', 'hidden')
    },

    isImages(step: any) {
      return step.match(/https?:\/\//)
    },

    onFileChange(event: any) {
      const file = event.target.files[0]
      const fileName = file.name.substr(-3).toLowerCase()
      this.fileError = false
      if (['png', 'jpg', 'peg'].indexOf(fileName) <= -1) {
        this.formProof.file = null
        this.proofPreview = ''
        const file = this.$refs.file as HTMLInputElement
        file.value = ''
        this.fileError = true
        return
      }
      this.formProof.file = event.target.files[0]
      this.proofPreview = URL.createObjectURL(file)
    },

    async submitUploadProof() {
      if (
        this.formProof.name &&
        this.formProof.date &&
        this.formProof.file &&
        this.formProof.nominal
      ) {
        const payload = {
          name: this.formProof.name,
          nominal: this.formProof.nominal,
          file: this.formProof.file,
          date: moment(this.formProof.date).format('YYYY-MM-DD'),
        }
        this.loadingUpload = true
        await TransactionModel.sendPaymentConfirmation(
          this.detailTransaction?.id,
          payload
        )
          .then(async res => {
            if (res.success) {
              await this.getDetailTransaction()
              this.loadingUpload = false
              this.closeUploadProof()
              this.toast.success('Berhasil mengirim bukti bayar')
            } else {
              this.loadingUpload = false
              this.toast.error('Gagal mengirim bukti, silahkan coba kembali')
            }
          })
          .catch(err => {
            this.toast.error('Gagal mengirim bukti, silahkan coba kembali')
            this.loadingUpload = false
            console.log(err)
          })
      } else {
        return
      }
    },
    isNumber(event: any) {
      console.log(event)
      const charCode = event.which ? event.which : event.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        event.preventDefault()
      } else {
        return true
      }
    },
  },
})
